














import {
  Component, Vue, Prop, Watch,
} from 'vue-property-decorator';

import BoxContainer from '@/components/BoxContainer/BoxContainer.vue';
import FeedbackUser from '@/components/FeedbackUser/index.vue';

import MarketingLoading from './MarketingLoading.vue';

import PlusPlan from '@/assets/icons/PlusPlan.vue';
import CheckPlanIcon from '@/assets/icons/checkPlan.vue';

import { Marketing as IMarketing } from './interface';

import PaymentService from '@/services/Payment/PaymentService';

@Component({
  components: {
    BoxContainer,
    PlusPlan,
    CheckPlanIcon,
    FeedbackUser,
    MarketingLoading,
  },
})
export default class Marketing extends Vue {
  @Prop() planId!: number;

  private isLoading = false;

  private marketing: IMarketing | null = null;

  private PaymentService = new PaymentService();

  @Watch('planId', {
    immediate: true,
  })
  async getMarketing() {
    try {
      this.isLoading = true;
      this.marketing = null;

      if (this.planId !== null) {
        const [marketing] = await this.PaymentService.getMarketing(this.planId);

        if (marketing) this.marketing = marketing;
      }
    } catch (error) {
      this.$store.dispatch('Toast/setToast', {
        text: 'Erro ao carregar informações adicionais do plano.',
        status: 'error',
      });

      console.error(error);
    } finally {
      this.isLoading = false;
    }
  }

}
