



























































































import { Component, Vue } from 'vue-property-decorator';

import Banner from '@/components/Banner/Banner.vue';
import FeedbackUser from '@/components/FeedbackUser/index.vue';

import PlanBannerLoading from './PlanBannerLoading.vue';

import Cart from '@/assets/icons/Cart.vue';
import SaleStar from '@/assets/icons/SaleStar.vue';

import { TYPE_DISCOUNT, LIST_INTERVAL } from '../../../../constants/index';

import { Discount, Plan } from '../../../../interfaces/IShopping';

import { CurrencyNumberFormat } from '@/share/Util/NumberFormat';

import PlansService from '@/services/Plans/PlansService';

interface ListInterval {
  title: string;
  value: number;
}

@Component({
  components: {
    Banner,
    Cart,
    SaleStar,
    PlanBannerLoading,
    FeedbackUser,
  },
  filters: {
    formatInterval(value: number) {
      if (!value) return '';

      const planInterval = LIST_INTERVAL.find((interval: ListInterval) => (interval.value === value));

      return planInterval ? planInterval.title : '';
    },
    formatTitle(title: string) {
      return title.replace(/plano/i, '').trim();
    },
  },
})
export default class PlanBanner extends Vue {
  private isLoading = false;

  private planId = Number(this.$route.params.productId);

  private TYPE_DISCOUNT = TYPE_DISCOUNT;
  private plan: Plan | null = null;

  private planIntervals: Plan[] = [];

  private PlansService = new PlansService();

  created() {
    this.getPlan();
  }

  get price() {
    return {
      price: this.plan ? this.handlePriceDiscount(this.plan, TYPE_DISCOUNT.CREDIT_CART) : '',
      pricePix: this.plan ? this.handlePriceDiscount(this.plan, TYPE_DISCOUNT.PIX) : '',
    };
  }

  async getPlan() {
    try {
      this.isLoading = true;

      const response = await this.PlansService.getPlans({
        withRelations: 1,
        planId: this.planId,
      });

      if (response && response?.plans) {
        this.plan = response.plans;
        this.planIntervals = this.setPlanIntervals(response.plans);
      }
    } catch (error) {
      this.$store.dispatch('Toast/setToast', {
        text: 'Erro ao carregar o plano.',
        status: 'error',
      });

      console.error(error);
    } finally {
      this.isLoading = false;
    }
  }

  setPlanIntervals(plan: Plan) {
    let plans = [plan];

    if (plan?.plansRelatedData) {
      plans = [
        ...plan.plansRelatedData,
      ];
    }

    let plansWithChecked = this.addCheckedToPlanInterval(plans);

    plansWithChecked = this.setOrderPlanInterval(plansWithChecked);

    return plansWithChecked;
  }

  addCheckedToPlanInterval(plans: Plan[]) {
    let plansWithChecked: Plan[] = plans;

    if (plans.length === 1) {
      plansWithChecked = plans.map((plan: Plan) => (
        {
          ...plan,
          checked: true,
        }
      ));

      return plansWithChecked;
    }

    plansWithChecked = plans.map((plan: Plan) => {
      if (plan.planId === this.plan?.planId) {
        return { ...plan, checked: true };
      }

      return { ...plan, checked: false };
    });

    return plansWithChecked;
  }

  setOrderPlanInterval(plans: Plan[]) {
    const order = [1, 3, 6, 12];

    return plans.sort((a, b) => {
      const indexA = order.indexOf(a.planIntervalCount);
      const indexB = order.indexOf(b.planIntervalCount);

      if (indexA === -1) return 1;
      if (indexB === -1) return -1;

      return indexA - indexB;
    });
  }

  handlePriceDiscount(plan: Plan, type: string) {
    const totalPrice = plan.planPrice;

    const foundDiscountPrice = plan.discounts.find((discount: Discount) => discount.code === type);

    if (!foundDiscountPrice) return totalPrice;

    let price = 0;

    if (foundDiscountPrice.percent) {
      price = (totalPrice * (foundDiscountPrice.percent / 100)) < foundDiscountPrice.max_value
        ? totalPrice - foundDiscountPrice.max_value
        : totalPrice - totalPrice * (foundDiscountPrice.percent / 100);
    }

    if (foundDiscountPrice.value) {
      price = totalPrice - foundDiscountPrice.value;
    }

    return price;
  }

  async changePlanInterval(planInterval: Plan) {
    this.planIntervals = this.planIntervals.map((plan: Plan) => {
      if (plan.planId === planInterval.planId) {
        this.planId = plan.planId;
        return { ...plan, checked: true };
      }

      return { ...plan, checked: false };
    });

    if (this.planId !== this.plan?.planId) {
      this.$emit('change-plan-interval', this.planId);

      await this.getPlan();
    }
  }

  handleClickBuyProduct(id: number) {
    this.$router.push({
      name: 'Payment',
      params: {
        feature: 'planos',
        productId: String(id),
      },
    });
  }

}
